import "styled-components";
import { defaultSize } from "./size";

export type FontSize =
  | "SUPERXXL"
  | "SUPERXL"
  | "SUPERL"
  | "XXXXXL"
  | "XXXXL"
  | "XXXLX"
  | "XXXL"
  | "XXL"
  | "XL"
  | "LG"
  | "MD"
  | "SM"
  | "SXS"
  | "XS"
  | "XXS";

export const defaultFontSize: Readonly<Record<FontSize, string>> = {
  SUPERXXL: "3.5rem", //56px
  SUPERXL: "3rem", //48px
  SUPERL: "2.5rem", //40px
  XXXXXL: "2.25rem", //36px
  XXXXL: "2rem", //32px
  XXXLX: "1.75rem", //28px
  XXXL: "1.5rem", //24px
  XXL: "1.375rem", //22px
  XL: "1.25rem", //20px
  LG: "1.125rem", //18px
  MD: "1rem", //16px
  SM: "0.875rem", //14px
  SXS: "0.8125rem", //13px
  XS: "0.75rem", //12px
  XXS: "0.625rem", //10px
};

type screenSizeMax = "smallSmartPhoneMax" | "smartPhoneMax" | "smallPCMax";

export const defaultScreenSize: Readonly<Record<screenSizeMax, string>> = {
  smallSmartPhoneMax: "380px",
  smartPhoneMax: "800px",
  smallPCMax: "1200px",
};

type screenSize = "smallSmartPhone" | "smartPhone" | "smallPC";

type BreakPoint =
  | `minWidth${Capitalize<screenSize>}`
  | `maxWidth${Capitalize<screenSize>}`;

export const defaultBreakPoints: Record<BreakPoint, string> = {
  maxWidthSmallSmartPhone: `(max-width: ${defaultScreenSize.smallSmartPhoneMax})`,
  minWidthSmallSmartPhone: `(min-width: ${defaultScreenSize.smallSmartPhoneMax})`,
  maxWidthSmartPhone: `(max-width: ${defaultScreenSize.smartPhoneMax})`,
  minWidthSmartPhone: `(min-width: ${defaultScreenSize.smartPhoneMax})`,
  maxWidthSmallPC: `(max-width: ${defaultScreenSize.smallPCMax})`,
  minWidthSmallPC: `(min-width: ${defaultScreenSize.smallPCMax})`,
};

export const theme = {
  colors: {
    base: {
      white: "#FFFFFF",
      paleOrange: "#FFFDFB",
      ashGray: "#F6F6F6",
      lightGray: "#EFEDEB",
      gray: "#D5D2CD",
      darkGray: "#7A7876",
      background: "#F6F6F6",
      overlay: "rgba(0,0,0,0.45)",
    },
    pri: {
      p100: "#FBF8F3",
      p200: "#FFF0D7",
      p300: "#F9C364",
      p400: "#EFAF37",
      p500: "#E8920A",
      p600: "#E28B02",
    },
    sec: {
      s100: "#CCE1F4",
      s200: "#80B3E4",
      s300: "#3386D4",
      s400: "#0068C9",
      s500: "#005EB5",
      s600: "#0053A1",
      s700: "#003E79",
    },
    tex: {
      t100: "#CECECE",
      t200: "#B4B4B4",
      t300: "#828282",
      t400: "#6E6E6E",
      t500: "#505050",
      t600: "#464646",
      t700: "#323232",
      t800: "#1E1E1E",
      t900: "#000000",
    },
    green: {
      g100: "#C3E9C0",
      g200: "#9BDB95",
      g300: "#79CF72",
      g400: "#69C361",
      g500: "#5EBC56",
      g600: "#55B34D",
    },
    red: {
      r100: "#F7BCBC",
      r200: "#F39A9A",
      r300: "#ED6868",
      r400: "#EB5757",
      r500: "#D34E4E",
      r600: "#BE3E3E",
    },
    sky: {
      s100: "#A4D9F6",
      s200: "#86CCF3",
      s300: "#68BFF0",
      s400: "#5FB6E7",
      s500: "#4FA6D7",
      s600: "#459CCD",
    },
    violet: {
      v100: "#EDD4F5",
      v200: "#D9A5F7",
      v300: "#C17CF8",
      v400: "#AA57EB",
      v500: "#9E4BDF",
      v600: "#9643D7",
    },
    wood: {
      w100: "#E6DAD3",
      w200: "#CDB5A8",
      w300: "#B3907C",
      w400: "#A1745B",
      w500: "#814625",
      w600: "#723E21",
    },
    line: {
      line100: "#B4EECC",
      line200: "#82E3AA",
      line300: "#51D888",
      line400: "#06C755",
      line500: "#05B34C",
      line600: "#059F44",
    },
    transparent: {
      t45: "rgba(0, 0, 0, 0.45)",
    },
  },
  zIndex: {
    base: 0,
    navbar: 100,
    blackBack: 800,
    element: 10,
    modal: 1000,
    popup: 500,
  },
  fontSize: defaultFontSize,
  screenSize: defaultScreenSize,
  breakPoints: defaultBreakPoints,
  fontFamily: {
    default: "'Noto Sans JP', sans-serif",
  },
  borderRadius: {
    XS: "6px",
    S: "8px",
    SM: "12px",
    MD: "16px",
    LG: "22px",
  },
  size: defaultSize,
} as const;

type AppTheme = typeof theme;

declare module "styled-components" {
  interface DefaultTheme extends AppTheme {} //eslint-disable-line
}
